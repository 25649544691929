import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "bienvenido-a-enviamelo---woocommerce-docs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bienvenido-a-enviamelo---woocommerce-docs",
        "aria-label": "bienvenido a enviamelo   woocommerce docs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bienvenido a `}<strong parentName="h1">{`Enviamelo - WooCommerce docs`}</strong>{`.`}</h1>
    <p>{`Esta aplicación te permite conectar `}<strong parentName="p">{`Enviamelo`}</strong>{` como medio de envío en tu tienda `}<strong parentName="p">{`WooCommerce`}</strong>{` y así poder seguir desde un solo lugar las operaciones logísticas de tu tienda.`}</p>
    <p>{`Durante el siguiente recorrido obtendrás información necesaria para configurar y utilizar este plugin, con el fin de brindarte un soporte sobre su funcionamiento dentro de la tienda `}<strong parentName="p">{`WooCommerce`}</strong>{`.`}</p>
    <h2 {...{
      "id": "requisitos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#requisitos",
        "aria-label": "requisitos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requisitos`}</h2>
    <p>{`Para poder operar con el plugin necesitarás tener una cuenta en `}<a href="https://app.enviamelo.com.ar/login" target="_blank" style={{
        "color": "#f68d1e",
        "background": "url('/img/link.png') no-repeat 100% 0",
        "backgroundSize": "16px 16px",
        "paddingRight": "19px"
      }}><u>{`Enviamelo`}</u></a>{`. Para ello necesitarás:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`E-mail`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Contraseña`}</strong></li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/img/001.png",
        "alt": "Requisitos"
      }}></img></p>
    <p>{`Una vez ingreses a tu cuenta en `}<strong parentName="p">{`Enviamelo`}</strong>{`, podrás encontrar las credenciales necesarias de la API.`}</p>
    <p>{`Adicionalmente para el correcto funcionamiento de la integración será necesario:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`WordPress`}</strong>{`: versión 5.4 o superior.`}</li>
      <li parentName="ul"><strong parentName="li">{`WooCommerce`}</strong>{`: versión 4.3.2 o superior.`}</li>
      <li parentName="ul">{`PHP: versión 7.0 o superior.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Importante`}</strong>{`: debes tener en cuenta que el plugin funciona únicamente para Argentina y que los productos comercializados deben tener configurados los valores de dimensiones y peso.`}</p>
    </blockquote>
    <h2 {...{
      "id": "plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#plugin",
        "aria-label": "plugin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Plugin`}</h2>
    <p>{`Última versión estable: `}<a href="/sdk/enviamelo-woo.SDK_1.0.7_PRD.zip" style={{
        "color": "#f68d1e",
        "background": "url('/img/link.png') no-repeat 100% 0",
        "backgroundSize": "16px 16px",
        "paddingRight": "19px"
      }}><u>{`Enviamelo WooCommerce 1.0.7`}</u></a>{`  `}</p>
    <Link to="/instalacion" style={{
      padding: "7.5px 15px",
      backgroundColor: "#f68d1e",
      color: "black",
      borderRadius: "5px"
    }} mdxType="Link">Inicia ahora</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      